function fireUpdateLocationAnalytics(e) {
  window.dataLayer = window.dataLayer || [];

  const eventData = {
    event: 'trackEvent',
    eventInfo: {
      ucsId: 'UCS-nbc-locator-CCTA',
      ucsState: 'non-pv',
      eventName: 'clickCTA',
      eventSource: 'nbc',
      eventTime: new Date().toISOString(),
      eventId: 'CCTA-branch-locator',
      interaction: 'cta:map:update-my-location',
    }
  };

  window.dataLayer.push(eventData);
  window.parent.postMessage(JSON.stringify(eventData), '*');
}

export const locationBiasListener = () => {
  const locationBiasButton = document.querySelector('.js-locationBias-update-location');
  if (locationBiasButton) {
    locationBiasButton.addEventListener('click', (e) => {
      e.stopImmediatePropagation();
      fireUpdateLocationAnalytics(e);
    });
  }
}

export function fireSearchButton(searchQuery) {
  const numResults = document.querySelector('.yxt-VerticalResultsCount-total') ? document.querySelector('.yxt-VerticalResultsCount-total').innerHTML : 0;
  const searchBar = document.querySelector('.yxt-SearchBar-form');
  window.dataLayer = window.dataLayer || [];

  let searchMethod = 'search-bar-in-map'
  if (searchBar && searchBar.dataset.voice === 'usedVoice'){
    searchMethod = 'voice-in-map';
  }

  const eventData = {
    event: 'trackEvent',
    eventInfo: {
      ucsId: 'UCS-nbc-locator-SCS',
      ucsState: 'non-pv',
      eventName: 'searchSite',
      eventSource: 'nbc',
      eventTime: new Date().toISOString(),
      eventId: 'SCS-branch-locator'
    },
    search: {
      searchTerms: searchQuery,
      searchMethod: searchMethod,
      searchResults: numResults
    }
  };

  window.dataLayer.push(eventData);
  window.parent.postMessage(JSON.stringify(eventData), '*');

  searchBar.dataset.voice = 'false';
}
